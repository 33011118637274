.login-container .logo {
  text-align: center;
  margin-bottom: 5px; }
  .login-container .logo img {
    width: 65%; }

.login-container .login-heading-container {
  text-align: center;
  margin-bottom: 15px; }

.login-container .login-form-container .login-form #email-helper-text,
.login-container .login-form-container .login-form #password-helper-text {
  font-size: 1rem;
  margin-left: 0;
  margin-bottom: 15px; }

.login-container .login-form-container .login-form .form-control {
  margin-bottom: 15px; }

.login-container .login-form-container .login-form .login-error-container {
  padding: 15px;
  color: #f44336;
  border: 1px solid #f44336;
  border-radius: 4px; }

.login-container .login-form-container .login-form .login-button-container {
  position: relative;
  margin-top: 15px; }
  .login-container .login-form-container .login-form .login-button-container button {
    padding: 16px 24px; }
  .login-container .login-form-container .login-form .login-button-container .button-progress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px; }
