@import url(~@fortawesome/fontawesome-pro/css/all.min.css);
html,
body {
  font-size: 14px;
  padding: 0;
  margin: 0;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  background-color: white; }

.page-padding-bottom {
  padding-bottom: 3rem; }
